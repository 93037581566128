import React, { useEffect, useContext, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import classnames from "classnames";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Breadcrumb from "components/LayoutFront/Breadcrumb";
import Icon from "components/templatesComponents/Icon";
import Link from "components/templatesComponents/Link";
import Navigation from "components/LayoutFront/Navigation";
import Footer from "components/LayoutFront/Footer";
import Content from "components/LayoutFront/Content";
import Wrapper from "components/LayoutFront/Wrapper";
import Share from "components/LayoutFront/Share";
import Banner from "components/LayoutFront/Banner";
import CookiesBanner from "components/LayoutFront/CookiesBanner";
import PageService from "services/PageService";
import useAxiosCache from "hooks/axiosCache";
import { useHistory, useLocation } from "react-router-dom";
import SiteContext from "components/SiteContext";
import { CookiesModalContextProvider } from "components/CookiesModalContext";
import LanguageContext from "components/LanguageContext";
import PageVersionContext from "components/PageVersionContext";
import news from "components/templates/news/news";
import lastNews from "components/templates/news/lastNews";
import allNews from "components/templates/news/allNews";
import PnuBanner from "components/LayoutFront/PnuBanner";
import { createQueryParams, getQueryParams } from "utils/urlUtils";
import t from "utils/locales/translation.json";

const pageWidth = "100%";

const useStyles = makeStyles((theme) => ({
  background: {
    background: "transparent",
    flex: "1 0 auto",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("lg")]: {
      background: theme.palette.componentColors[30],
    },
  },
  pnuBanner: {
    [theme.breakpoints.down("lg")]: {
      padding: theme.spacing(0, 3),
    },
  },
  headerPage: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "center",
    "@media print": {
      display: "none",
    },
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
      alignItems: "baseline",
    },
    [theme.breakpoints.between("md", "lg")]: {
      padding: theme.spacing(0, 3),
    },
  },
  share: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up("lg")]: {
      alignItems: "end",
    },
  },
  socialBackground: {
    backgroundColor: "#F2F2F9",
  },
  container: {
    display: "flex",
    alignItems: "center",
    "& h3": {
      fontSize: "1rem",
      fontWeight: "bold",
      minWidth: "200px",
      color: "var(--text-default-grey)",
      lineHeight: "1.5rem",
      margin: theme.spacing(3, 0),
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      paddingBottom: theme.spacing(3),
      "& h3": {
        fontSize: "1.125rem",
      },
    },
  },
  socialContainer: {
    "& a:not(:first-child)": {
      marginLeft: theme.spacing(2.5),
    },
    [theme.breakpoints.up("lg")]: {
      justifyContent: "flex-end",
    },
  },
  social: {
    fontSize: "1.25rem",
    color: "var(--text-action-high-blue-france)",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
      marginRight: theme.spacing(2),
    },
  },
}));

const LayoutFront = (props) => {
  const { children, bo } = props;

  const classes = useStyles();

  const history = useHistory();

  const [displayBanner, setDisplayBanner] = useState(true);
  const { language, setLanguage } = useContext(LanguageContext);
  const { currentPageVersion = {}, loading } = useContext(PageVersionContext);
  const { template, pageId, page: currentPage = {} } = currentPageVersion;
  const { originalPageId, lang: currentPageLanguage } = currentPage;
  const site = useContext(SiteContext);
  const { id: siteId, name: siteName, settings: siteSettings } = site || {};

  const { pathname: locationPathname, search } = useLocation();

  const queryParams = getQueryParams(search) || {};
  const { lang = language } = queryParams;

  const [{ data: homePage = [] }] = useAxiosCache(
    PageService.getConfig("findPages", {
      siteId,
      filters: [`page.lang||eq||${language}`, "page.parentId||isnull"],
    })
  );

  const [{ data: associatedPnuPages = [] }] = useAxiosCache(
    PageService.getConfig("findPages", {
      siteId,
      pageId: currentPageLanguage === "FR" ? pageId : originalPageId,
    }),
    { enabled: !loading }
  );

  const [{ data: allPnuPages = [] }] = useAxiosCache(
    PageService.getConfig("findPages", {
      siteId,
      filters: ["page.originalPageId||notnull"],
      perPage: currentPageLanguage === "FR" ? 1 : 200,
    })
  );

  const displayPnuBanner = useMemo(
    () =>
      displayBanner &&
      !!associatedPnuPages.length &&
      !associatedPnuPages.some((m) => m.page?.lang === lang.toUpperCase()),
    [displayBanner, associatedPnuPages, lang]
  );

  const displayShare = useMemo(
    () => !template || ![news.key, allNews.key, lastNews.key].includes(template),
    [template]
  );

  useEffect(() => {
    if (!allPnuPages.length) {
      setLanguage("FR");
      if (typeof localStorage !== "undefined") {
        localStorage.setItem("language", "FR");
      }
      if (typeof document.documentElement !== "undefined") {
        document.documentElement.lang = "fr";
      }
    } else {
      setLanguage(lang.toUpperCase());
      if (typeof localStorage !== "undefined") {
        localStorage.setItem("language", lang.toUpperCase());
      }
      if (typeof document.documentElement !== "undefined") {
        document.documentElement.lang = lang.toLowerCase();
      }
    }
  }, [setLanguage, lang, siteName, allPnuPages.length]);

  useEffect(() => setDisplayBanner(true), [locationPathname]);

  // Redirection vers la page associée à la langue du navigateur
  // (sauf page 404 et BO)
  useEffect(() => {
    if (
      typeof localStorage !== "undefined" &&
      associatedPnuPages.length > 0 &&
      associatedPnuPages.length <= 2 &&
      !bo &&
      Object.keys(currentPageVersion).length > 0 &&
      queryParams?.lang
    ) {
      const localStorageLang = localStorage.getItem("language");
      const getPageByLang = associatedPnuPages.find((p) => p.page?.lang === localStorageLang.toUpperCase());
      const pathname = getPageByLang?.fullPath || getPageByLang?.path || associatedPnuPages[0]?.fullPath;
      history.replace({
        pathname,
        search: `?${createQueryParams(queryParams)}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [associatedPnuPages, bo]);

  let { socialNetworks = [] } = siteSettings || {};

  if (socialNetworks && !Array.isArray(socialNetworks)) {
    socialNetworks = [socialNetworks];
  }

  const Social = () => (
    <div role="list">
      {socialNetworks.map((social) => {
        const { id, ...others } = social.icon;
        return (
          <Link
            role="listitem"
            url={social.url}
            external
            className={classes.social}
            key={social.id}
            title={social.network || null}
            aria-label={social.network || null}
            isSocialNetwork
          >
            <Icon {...others} />
            <p className="fr-sr-only">{social.network}</p>
          </Link>
        );
      })}
    </div>
  );

  return (
    <Navigation
      pageWidth={pageWidth}
      allPnuPages={allPnuPages}
      associatedPnuPages={associatedPnuPages}
      homePage={homePage[0]}
    >
      <CookiesModalContextProvider>
        <div className={classes.background}>
          <Content pageWidth={pageWidth}>
            <Banner />
            <Wrapper breakpoint="xl">
              {displayPnuBanner && (
                <div className={classes.pnuBanner}>
                  <PnuBanner language={language} setDisplayBanner={setDisplayBanner} />
                </div>
              )}
              <div className={classes.headerPage}>
                <Box>
                  <Breadcrumb bo={bo} />
                </Box>
                <Box className={classes.share} mx={1} mt={1} mb={displayShare ? 0 : 2}>
                  {displayShare && <Share />}
                </Box>
              </div>
            </Wrapper>
            <main id="main" role="main">
              {children}
            </main>
          </Content>
          <div className={classnames("fr-social", classes.socialBackground)}>
            <div className={classnames("fr-container", classes.container)}>
              <h3>
                {t[language].footer.social_networks_1}
                <br />
                {t[language].footer.social_networks_2}
              </h3>
              <Grid container className={classes.socialContainer}>
                <Social />
              </Grid>
            </div>
          </div>
          <Footer allPnuPages={allPnuPages} homePage={homePage[0]} />
        </div>
        <CookiesBanner bo={bo} />
      </CookiesModalContextProvider>
    </Navigation>
  );
};

LayoutFront.propTypes = {
  children: PropTypes.node,
  bo: PropTypes.bool,
};

LayoutFront.defaultProps = {
  children: null,
  bo: false,
};

export default LayoutFront;
